import Vue from 'vue';
import VueRouter from 'vue-router';
import {
    preventUnauthenticatedNavigation,
    preventNoNameNavigation,
    preventUnauthorizedNavigation,
    preventSignInWhenAuthenticated,
    trackRecent,
    clientOverview,
    removeHiddenOverflow,
    initialized,
} from './routeGuards';

Vue.use(VueRouter);

const routes = [
    {
        path: '/auth/sign-in',
        name: 'SignIn',
        component: () => import('../pages/auth/SignIn'),
    },
    {
        path: '/auth/reset-password',
        name: 'ResetPassword',
        component: () => import('../pages/auth/ResetPassword'),
        meta: { title: 'Set Password' },
    },
    {
        path: '/auth/puppet',
        name: 'SuperUserRedirect',
        component: () => import('../pages/auth/SuperUserRedirect'),
    },
    {
        path: '/select-company',
        name: 'SelectCompany',
        props: true,
        component: () => import('../pages/auth/SelectCompanyModal'),
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../pages/dash/Dashboard'),
    },
    {
        path: '/dashboard-builder/build-dashboard',
        name: 'DashboardBuilder',
        component: () => import('../pages/dashboard_builder/dashboard_builder_basic_layout'),
    },
    {
        path: '/dashboard-builder',
        name: 'DashboardBuilderList',
        props: true,
        component: () => import('../pages/dashboard_builder/dashboard_list'),
    },
    {
        path: '/alerts',
        name: 'Alerts',
        props: true,
        component: () => import('../pages/alerts/Alerts'),
    },
    {
        path: '/alert-detail/:record_id',
        name: 'AlertDetail',
        component: () => import('../pages/alerts/AlertDetail'),
        props: true,
    },
    {
        path: '/notes',
        name: 'Notes',
        component: () => import('../pages/notes/Notes'),
        props: true,
    },
    {
        path: '/note-detail/:record_id',
        name: 'NoteDetail',
        component: () => import('../pages/notes/NoteDetail'),
        props: true,
    },
    {
        path: '/forms',
        name: 'Forms',
        component: () => import('../pages/forms/Forms'),
        props: true,
    },
    {
        path: '/forms/:pageName',
        name: 'DynamicForm',
        component: () => import('../pages/forms/DynamicForm'),
    },

    {
        path: '/bio-psycho/:record_id/:puppet',
        name: 'BioPyschoSocialPDF',
        component: () => import('../pages/core_forms/BioPyschoSocial'),
        props: true,
    },
    {
        path: '/bio-psycho/:record_id',
        name: 'BioPyschoSocial',
        component: () => import('../pages/core_forms/BioPyschoSocial'),
        props: true,
    },
    {
        path: '/bio-psycho-short/:record_id/:puppet',
        name: 'BioPyschoSocialShortPDF',
        component: () => import('../pages/core_forms/BioPyschoSocialShort'),
        props: true,
    },
    {
        path: '/bio-psycho-short/:record_id',
        name: 'BioPyschoSocialShort',
        component: () => import('../pages/core_forms/BioPyschoSocialShort'),
        props: true,
    },
    {
        path: '/good-faith-estimate-for-mental-health-services/:record_id',
        name: 'GoodFaithEstimateForMentalHealthServices',
        component: () => import('../pages/core_forms/GoodFaithEstimateForMentalHealthServices'),
        props: true,
    },
    {
        path: '/good-faith-estimate-for-mental-health-services/:record_id/:puppet',
        name: 'GoodFaithEstimateForMentalHealthServicesPDF',
        component: () => import('../pages/core_forms/GoodFaithEstimateForMentalHealthServices'),
        props: true,
    },

    {
        path: '/accept-and-action/:record_id',
        name: 'AcceptanceAndActionQuestionnaire', // actual db.chirp.pages.component_name
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/adult-symptom-screener/:record_id',
        name: 'AdultSymptomScreener',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/ace-score/:record_id',
        name: 'AceScore',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/psychology-quest/:record_id',
        name: 'SelfTranscendance',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/ptsd/:record_id',
        name: 'Ptsd',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/gad7/:record_id',
        name: 'Gad7',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/kads/:record_id',
        name: 'Kutcher11',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/mood/:record_id',
        name: 'MoodDisorder',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/pediatric-symptom-checklist/:record_id',
        name: 'PediatricSymptom',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/phq/:record_id',
        name: 'Phq',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/phq9/:record_id',
        name: 'PhqNine',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/phq-15/:record_id',
        name: 'PhqFifteen',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/phq-sads/:record_id',
        name: 'PhqSads',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/social-anxiety-screening/:record_id',
        name: 'SocialAnxietyScreening',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/spin/:record_id',
        name: 'Spin',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/youth-pediatric-symptom/:record_id',
        name: 'YouthPediatricSymptom',
        component: () => import('../pages/core_forms/ChecklistForm'),
        props: true,
    },
    {
        path: '/standard-release-info/:record_id',
        name: 'StandardReleaseInformation',
        component: () => import('../pages/core_forms/StandardReleaseInformation'),
        props: true,
    },
    {
        path: '/release-info/:record_id',
        name: 'ReleaseInformation',
        component: () => import('../pages/core_forms/ReleaseInformation'),
        props: true,
    },
    {
        path: '/client-safety-plan/:record_id',
        name: 'ClientSafetyPlan',
        component: () => import('../pages/core_forms/ClientSafetyPlan'),
        props: true,
    },
    {
        path: '/informed-consent-for-therapy/:record_id',
        name: 'InformedConsentForTherapy',
        component: () => import('../pages/core_forms/InformedConsentForTherapy'),
        props: true,
    },
    {
        path: '/consent-for-clinical-observation/:record_id',
        name: 'ConsentForClinicalObservation',
        component: () => import('../pages/core_forms/ConsentForClinicalObservation'),
        props: true,
    },
    {
        path: '/telehealth-informed-consent/:record_id',
        name: 'TelehealthInformedConsent',
        component: () => import('../pages/core_forms/TelehealthInformedConsent'),
        props: true,
    },
    {
        path: '/good-faith-notice/:record_id',
        name: 'GoodFaithNotice',
        component: () => import('../pages/core_forms/GoodFaithNotice'),
        props: true,
    },
    {
        path: '/intern-informed-consent/:record_id',
        name: 'InternInformedConsent',
        component: () => import('../pages/core_forms/InternInformedConsent'),
        props: true,
    },
    {
        path: '/notice-of-privacy-practices/:record_id',
        name: 'NoticeOfPrivacyPractices',
        component: () => import('../pages/core_forms/NoticeOfPrivacyPractices'),
        props: true,
    },
    {
        path: '/practice-policies/:record_id',
        name: 'PracticePolicies',
        component: () => import('../pages/core_forms/PracticePolicies'),
        props: true,
    },
    {
        path: '/authorization-to-enable-autopay/:record_id',
        name: 'AuthorizationToEnableAutopay',
        component: () => import('../pages/core_forms/AuthorizationToEnableAutopay'),
        props: true,
    },
    {
        path: '/permit-holder-informed-consent/:record_id',
        name: 'PermitHolderInformedConsent',
        component: () => import('../pages/core_forms/PermitHolderInformedConsent'),
        props: true,
    },
    {
        path: '/consent-to-receive-electronic-communication/:record_id',
        name: 'ConsentToReceiveElectronicCommunication',
        component: () => import('../pages/core_forms/ConsentToReceiveElectronicCommunication'),
        props: true,
    },
    {
        path: '/client-expectations/:record_id',
        name: 'ClientExpectations',
        component: () => import('../pages/core_forms/ClientExpectations'),
        props: true,
    },
    {
        path: '/fee-schedule-rogerian/:record_id',
        name: 'FeeScheduleRogerian',
        component: () => import('../pages/core_forms/FeeScheduleRogerian'),
        props: true,
    },
    {
        path: '/client-rights/:record_id',
        name: 'ClientRights',
        component: () => import('../pages/core_forms/ClientRights'),
        props: true,
    },
    {
        path: '/referral-list-verification/:record_id',
        name: 'ReferralListVerification',
        component: () => import('../pages/core_forms/ReferralListVerification'),
        props: true,
    },
    {
        path: '/informed-consent/:record_id',
        name: 'InformedConsent',
        component: () => import('../pages/core_forms/InformedConsent'),
        props: true,
    },
    {
        path: '/intake/:record_id',
        name: 'Intake',
        component: () => import('../pages/core_forms/Intake'),
        props: true,
    },
    {
        path: '/iop-admission-asam/:record_id',
        name: 'IntensiveOutpatientASAM',
        component: () => import('../pages/core_forms/IntensiveOutpatientASAM'),
        props: true,
    },
    {
        path: '/iop-continued-stay-asam/:record_id',
        name: 'ContinuedStayASAM',
        component: () => import('../pages/core_forms/ContinuedStayASAM'),
        props: true,
    },
    {
        path: '/iop-transfer-discharge-asam/:record_id',
        name: 'DischargeTransferASAM',
        component: () => import('../pages/core_forms/DischargeTransferASAM'),
        props: true,
    },
    {
        path: '/op-admission-asam/:record_id',
        name: 'OpAdmissonASAM',
        component: () => import('../pages/core_forms/OpAdmissonASAM'),
        props: true,
    },
    {
        path: '/op-continued-stay-asam/:record_id',
        name: 'OpContinuedStayASAM',
        component: () => import('../pages/core_forms/OpContinuedStayASAM'),
        props: true,
    },
    {
        path: '/op-transfer-discharge-asam/:record_id',
        name: 'OpDischargeTransferASAM',
        component: () => import('../pages/core_forms/OpDischargeTransferASAM'),
        props: true,
    },
    {
        path: '/suncoast-safety/:record_id',
        name: 'SuncoastSafety',
        component: () => import('../pages/core_forms/SuncoastSafety'),
        props: true,
    },
    {
        path: '/consent-form/:record_id',
        name: 'ConsentForm',
        component: () => import('../pages/core_forms/ConsentForm'),
        props: true,
    },
    {
        path: '/treatment-plan/:record_id',
        name: 'TreatmentPlan',
        component: () => import('../pages/core_forms/TreatmentPlan'),
        props: true,
    },
    {
        path: '/treatment-review/:record_id',
        name: 'TreatmentPlanReview',
        component: () => import('../pages/core_forms/TreatmentPlanReview'),
        props: true,
    },
    {
        path: '/discharge/:record_id',
        name: 'Discharge',
        component: () => import('../pages/core_forms/Discharge'),
        props: true,
    },

    {
        path: '/not-found',
        name: 'NotFound',
        component: () => import('../pages/NotFound'),
    },
    // {
    //     path: '/company-info',
    //     name: 'CompanyDetail',
    //     component: () => import('../pages/company/CompanyDetail')
    // },
    {
        path: '/company-codes',
        name: 'CompanyCodes',
        component: () => import('../pages/company/CompanyCodes'),
    },
    {
        path: '/company-service-fees',
        name: 'CompanyServiceFees',
        component: () => import('../pages/company/CompanyServiceFees'),
    },
    {
        path: '/fee-schedule',
        name: 'FeeSchedule',
        component: () => import('../pages/company/FeeSchedule'),
        props: true,
    },
    {
        path: '/company-products',
        name: 'CompanyProducts',
        component: () => import('../pages/company/CompanyProducts'),
    },
    {
        path: '/company-insurance',
        name: 'CompanyInsurance',
        component: () => import('../pages/company/CompanyInsurance'),
    },
    {
        path: '/company-payment',
        name: 'CompanyPayment',
        component: () => import('../pages/company/CompanyPayment'),
    },
    {
        path: '/company-invoice-statements',
        name: 'CompanyInvoiceStatements',
        component: () => import('../pages/company/CompanyInvoiceStatements'),
    },
    {
        path: '/company-settings',
        name: 'CompanySettings',
        component: () => import('../pages/company/CompanySettings'),
    },

    {
        path: '/rooms',
        name: 'Rooms',
        component: () => import('../pages/rooms/Rooms'),
        props: true,
    },
    {
        path: '/room/:record_id',
        name: 'Room',
        component: () => import('../pages/rooms/Room'),
        props: true,
    },

    {
        path: '/referrals',
        name: 'ReferralsList',
        component: () => import('../pages/referrals/ReferralsList'),
        props: true,
    },
    {
        path: '/referral-detail/:record_id',
        name: 'ReferralDetail',
        component: () => import('../pages/referrals/ReferralDetail'),
        props: true,
    },

    {
        path: '/offices',
        name: 'Offices',
        component: () => import('../pages/offices/Offices'),
        props: true,
    },
    {
        path: '/office-info/:record_id',
        name: 'OfficeDetail',
        component: () => import('../pages/offices/OfficeDetail'),
        props: true,
    },
    {
        path: '/office-codes',
        name: 'OfficeCodes',
        component: () => import('../pages/offices/OfficeCodes'),
    },
    {
        path: '/office-insurance',
        name: 'OfficeInsurance',
        component: () => import('../pages/offices/OfficeInsurance'),
    },
    {
        path: '/office-payment',
        name: 'OfficePayment',
        component: () => import('../pages/offices/OfficePayment'),
    },
    {
        path: '/office-invoice-statements',
        name: 'OfficeInvoiceStatements',
        component: () => import('../pages/offices/OfficeInvoiceStatements'),
    },
    {
        path: '/office-settings',
        name: 'OfficeSettings',
        component: () => import('../pages/offices/OfficeSettings'),
    },

    {
        path: '/users',
        name: 'Users',
        props: true,
        component: () => import('../pages/users/Users'),
    },
    {
        path: '/users/:userId',
        name: 'UserDetail',
        component: () => import('../pages/users/UserDetail'),
        props: true,
    },
    {
        path: '/user-settings',
        name: 'UserSettings',
        component: () => import('../pages/users/UserSettings'),
    },
    {
        path: '/user-setting/:record_id',
        name: 'SettingDetail',
        component: () => import('../pages/settings/SettingDetail'),
    },

    {
        path: '/clients',
        name: 'Clients',
        props: true,
        component: () => import('../pages/clients/Clients'),
    },
    {
        path: '/client/:record_id',
        name: 'ClientOverview',
        component: () => import('../pages/clients/ClientOverview'),
        props: true,
    },

    {
        path: '/client-demographics/:client_id',
        name: 'ClientDemographics',
        component: () => import('../pages/clients/ClientDemographics'),
    },
    // {
    //     path: '/client-insurance',
    //     name: 'ClientInsurance',
    //     component: () => import('../pages/clients/ClientInsurance'),
    // },
    {
        path: '/client-billing/statement',
        name: 'Statement',
        component: () => import('../pages/billing/Statement'),
    },
    {
        path: '/client-billing/:client_id',
        name: 'ClientBilling',
        component: () => import('../pages/clients/ClientBilling'),
    },
    {
        path: '/client-clinical/:client_id',
        name: 'ClientClinical',
        component: () => import('../pages/clients/ClientClinical'),
    },
    {
        path: '/client-documents/:client_id',
        name: 'ClientDocuments',
        component: () => import('../pages/clients/ClientDocuments'),
    },

    {
        path: '/client-insurance/:client_id',
        name: 'ClientInsurance',
        component: () => import('../pages/clients/ClientInsurance'),
    },
    {
        path: '/client-leads',
        name: 'ClientLeads',
        component: () => import('../pages/clients/ClientLeads'),
    },
    {
        path: '/client-settings',
        name: 'ClientSettings',
        component: () => import('../pages/clients/ClientSettings'),
    },

    {
        path: '/forms-library',
        name: 'FormsLibrary',
        component: () => import('../pages/clients/FormsLibrary'),
        props: true,
    },

    {
        path: '/calendar',
        name: 'Calendar',
        props: true,
        component: () => import('../pages/calendar/Calendar'),
    },
    {
        path: '/appointments',
        name: 'Appointments',
        props: true,
        component: () => import('../pages/calendar/Appointments'),
    },
    {
        path: '/appt-detail/:record_id',
        name: 'AppointmentDetail',
        component: () => import('../pages/calendar/AppointmentDetail'),
        props: true,
    },

    {
        path: '/email',
        name: 'EmailEditor',
        component: () => import('../pages/email/EmailEditor'),
    },
    // {
    //     path: '/email-editor',
    //     name: 'EmailEditor',
    //     component: () => import('../pages/email/EmailEditor'),
    //     props: true
    // },
    {
        path: '/address-book',
        name: 'EmailAddressList',
        component: () => import('../pages/email/EmailAddressBook'),
        props: true,
    },
    {
        path: '/email-templates',
        name: 'EmailTemplates',
        component: () => import('../pages/email/EmailTemplates'),
        props: true,
    },
    {
        path: '/email-template-detail/:record_id',
        name: 'EmailTemplateDetail',
        component: () => import('../pages/email/EmailTemplateDetail'),
        props: true,
    },
    {
        path: '/emails-scheduled',
        name: 'EmailsScheduled',
        component: () => import('../pages/email/EmailsScheduled'),
    },
    {
        path: '/emails-log-detail/:record_id',
        name: 'EmailsLogDetail',
        component: () => import('../pages/email/EmailLogDetail'),
    },
    {
        path: '/emails-log',
        name: 'EmailsLog',
        component: () => import('../pages/email/EmailLog'),
        props: true,
    },

    {
        path: '/billing',
        name: 'Billing',
        component: () => import('../pages/billing/Billing'),
    },
    {
        path: '/payments',
        name: 'Payments',
        props: true,
        component: () => import('../pages/billing/Payments'),
    },
    {
        path: '/payment-detail/:record_id',
        name: 'PaymentDetail',
        component: () => import('../pages/billing/PaymentDetail'),
        props: true,
    },
    {
        path: '/ledger',
        name: 'Ledger',
        props: true,
        component: () => import('../pages/billing/Ledger'),
    },
    {
        path: '/ledger-detail/:record_id',
        name: 'LedgerDetail',
        component: () => import('../pages/billing/LedgerDetail'),
        props: true,
    },
    {
        path: '/service-fees',
        name: 'ServiceFees',
        component: () => import('../pages/billing/ServiceFees'),
    },
    {
        path: '/invoices',
        name: 'Invoices',
        props: true,
        component: () => import('../pages/billing/Invoices'),
    },

    {
        path: '/insurance-payers',
        name: 'InsurancePayers',
        component: () => import('../pages/insurance/InsurancePayers'),
        props: true,
    },
    {
        path: '/insurance-payer-detail/:record_id',
        name: 'InsurancePayerDetail',
        component: () => import('../pages/insurance/InsurancePayerDetail'),
        props: true,
    },

    {
        path: '/insurance',
        name: 'Insurance',
        component: () => import('../pages/insurance/Insurance'),
    },
    {
        path: '/insurance-claims',
        name: 'InsuranceClaims',
        component: () => import('../pages/insurance/InsuranceClaims'),
        props: true,
    },
    {
        path: '/insurance-claim/:record_id',
        name: 'InsuranceClaimDetail',
        component: () => import('../pages/insurance/InsuranceClaimDetail'),
        props: true,
    },
    {
        path: '/insurance-eras',
        name: 'InsuranceERAs',
        component: () => import('../pages/insurance/InsuranceERAs'),
        props: true,
    },
    {
        path: '/insurance-era/:record_id',
        name: 'InsuranceERADetail',
        component: () => import('../pages/insurance/InsuranceERADetail'),
        props: true,
    },
    {
        path: '/insurance-verifications',
        name: 'InsuranceVerifications',
        component: () => import('../pages/insurance/InsuranceVerifications'),
        props: true,
    },
    {
        path: '/insurance-verification/:record_id',
        name: 'InsuranceVerificationDetail',
        component: () => import('../pages/insurance/InsuranceVerificationDetail'),
        props: true,
    },

    {
        path: '/reports',
        name: 'Reports',
        props: true,
        component: () => import('../pages/reports/Reports'),
    },
    {
        path: '/report/:report_name',
        name: 'Report',
        component: () => import('../pages/reports/Report'),
        props: true,
    },

    {
        path: '/report-unpaid-claims',
        name: 'UnpaidClaims',
        props: true,
        component: () => import('../pages/reports/UnpaidClaims'),
    },
    {
        path: '/report-attendance',
        name: 'Attendance',
        props: true,
        component: () => import('../pages/reports/Attendance'),
    },
    {
        path: '/report-client-aging',
        name: 'Aging',
        props: true,
        component: () => import('../pages/reports/Aging'),
    },
    {
        path: '/report-appt-status',
        name: 'ApptStatus',
        props: true,
        component: () => import('../pages/reports/ApptStatus'),
    },
    {
        path: '/report-invoice-status',
        name: 'InvoiceStatus',
        props: true,
        component: () => import('../pages/reports/InvoiceStatus'),
    },
    {
        path: '/report-monthly-income',
        name: 'Income',
        props: true,
        component: () => import('../pages/reports/Income'),
    },
    {
        path: '/report-therapist-income',
        name: 'TherapistIncome',
        props: true,
        component: () => import('../pages/reports/TherapistIncome'),
    },
    {
        path: '/report-therapist-production',
        name: 'TherapistProduction',
        props: true,
        component: () => import('../pages/reports/TherapistProduction'),
    },
    {
        path: '/report-client-income',
        name: 'ClientIncome',
        props: true,
        component: () => import('../pages/reports/ClientIncome'),
    },
    {
        path: '/report-daily-transaction',
        name: 'DailyTransaction',
        component: () => import('../pages/reports/DailyTransaction'),
        props: true,
    },
    {
        path: '/report-full-financial',
        name: 'FullFinancialView',
        component: () => import('../pages/reports/FullFinancialView'),
        props: true,
    },
    {
        path: '/report-discharge',
        name: 'DischargeReport',
        component: () => import('../pages/reports/DischargeReport'),
        props: true,
    },
    {
        path: '/discharge-detail/:record_id',
        name: 'DischargeDetail',
        component: () => import('../pages/core_forms/DischargeDetail'),
        props: true,
    },
    {
        path: '/progress-note/:id/:puppet',
        name: 'ProgressNotePDF',
        props: true,
        component: () => import('../pages/core_forms/ProgressNote'),
    },
    {
        path: '/progress-note/:id',
        name: 'ProgressNote',
        props: true,
        component: () => import('../pages/core_forms/ProgressNote'),
    },
    {
        path: '/chart-note/:id',
        name: 'ChartNote',
        props: true,
        component: () => import('../pages/chart_note/ChartNote'),
    },
    {
        path: '/toxicology-report/:record_id',
        name: 'ToxicologyReport',
        component: () => import('../pages/core_forms/ToxicologyReport'),
        props: true,
    },
    {
        path: '/video-conferences',
        name: 'VideoConferences',
        component: () => import('../pages/video/VideoConferences'),
    },
    {
        path: '/logs',
        name: 'Logs',
        component: () => import('../pages/dev/Logs'),
        props: true,
    },
    {
        path: '/form-builder',
        name: 'FormBuilder',
        component: () => import('../pages/dev/FormBuilder'),
        props: true,
    },
    {
        path: '/documents',
        name: 'Documents',
        props: true,
        component: () => import('../pages/dev/Documents'),
    },

    {
        path: '/scratch-pad',
        name: 'ScratchPad',
        component: () => import('../pages/ScratchPad'),
    },

    {
        path: '/client-billing/:client_id/invoices',
        name: 'ClientInvoices',
        props: true,
        component: () => import('../pages/clients/ClientInvoices'),
    },
    {
        path: '/client-billing/:client_id/invoices/:invoice_id',
        name: 'ClientInvoice',
        props: true,
        component: () => import('../pages/clients/ClientInvoice'),
    },
    {
        path: '/client-billing/:client_id/transactions',
        name: 'ClientTransactions',
        props: true,
        component: () => import('../pages/clients/ClientTransactions'),
    },
    {
        path: '/invoices/superbill',
        name: 'Superbill',
        props: (route) => ({
            clientId: route.query.clientId,
            startDate: route.query.startDate,
            endDate: route.query.endDate,
        }),
        component: () => import('../pages/billing/Superbill'),
    },
    {
        path: '/invoices/:invoice_id',
        name: 'Invoice',
        props: true,
        component: () => import('../pages/invoices/Invoice'),
    },
    {
        path: '/certificate-of-completion/:record_id',
        name: 'CertificateCompletion',
        props: true,
        component: () => import('../pages/core_forms/CertificateCompletion'),
    },
    {
        path: '/client-photos/:client_id',
        name: 'ClientPhotos',
        props: true,
        component: () => import('../pages/photos/Photos'),
    },
    {
        path: '/client-link-sharing/:client_id',
        name: 'Link Sharing',
        props: true,
        component: () => import('../pages/link_sharing/LinkSharing'),
    },
    {
        path: '/client-medications/:client_id',
        name: 'Medications',
        props: true,
        component: () => import('../pages/medications/Medications'),
    },
    {
        path: '/client-messaging/:client_id',
        name: 'Messaging',
        props: true,
        component: () => import('../pages/client_messaging/Messaging'),
    },
    {
        path: '/client-vitals/:client_id',
        name: 'Vitals',
        props: true,
        component: () => import('../pages/vitals/Vitals'),
    },
    {
        path: '/add-member',
        name: 'AddNewMember',
        props: true,
        component: () => import('../pages/add_member/AddMember'),
    },
    {
        path: '/add-company',
        name: 'AddNewCompany',
        props: true,
        component: () => import('../pages/add_company/AddCompany'),
    },
    {
        path: '/company-list',
        name: 'CompanyList',
        props: true,
        component: () => import('../pages/company/CompanyList'),
    },
    {
        path: '/company',
        name: 'CompanyDetail',
        component: () => import('../pages/company/CompanyDetail'),
        // props: true
    },
    {
        path: '/company-update/:companyId',
        name: 'CompanyUpdate',
        component: () => import('../pages/company/CompanyDetail'),
        props: true,
    },
    {
        path: '/rss-builder',
        name: 'RSSBuilderList',
        props: true,
        component: () => import('../pages/rss_builder/rss_list'),
    },

    {
        path: '/rss-builder/build-rss',
        name: 'RSSBuilder',
        props: true,
        component: () => import('../pages/rss_builder/rss_builder'),
    },
    {
        path: '/lookup-table',
        name: 'LookupTableBuilderList',
        props: true,
        component: () => import('../pages/lookup_table/lookup_table_list.vue'),
    },

    {
        path: '/lookup-table/build-lookup-table',
        name: 'LookupTableBuilder',
        props: true,
        component: () => import('../pages/lookup_table/lookup-table-builder.vue'),
    },
    {
        path: '/file-manager',
        name: 'FileManagerList',
        props: true,
        component: () => import('../pages/file_manager/file_manager_list'),
    },
    {
        path: '/language-translation',
        name: 'LanguageTranslation',
        props: true,
        component: () => import('../pages/language_translation/language_translation'),
    },
    {
        path: '/svg-icons-section',
        name: 'SvgIconsSection',
        props: true,
        component: () => import('../pages/svg_icons_section/svg_icons_section'),
    },
    {
        path: '/third-party-appplication',
        name: 'ThirdPartyApplicationList',
        props: true,
        component: () => import('../pages/third_party_application/third_party_apps_list'),
    },
];

/* FOR DEV USE ONLY - THESE PAGES MAY NOT BE ON PROD - and only on HQ */
if (process.env.VUE_APP_AWS_ENV == 'dev') {
    routes.push({
        path: '/dev',
        name: 'Dev',
        component: () => import('../pages/dev/Dev'),
    });
}
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        } else {
            return { x: 0, y: 0, behavior: 'auto' };
        }
    },
});

router.beforeEach(preventNoNameNavigation);
router.beforeEach(preventSignInWhenAuthenticated);
router.beforeEach(preventUnauthenticatedNavigation);
router.beforeEach(preventUnauthorizedNavigation);
router.beforeEach(initialized);
router.beforeEach(trackRecent);
router.beforeEach(clientOverview);
router.beforeEach(removeHiddenOverflow); // Doing this to remove overflow: hidden when navigating to new page while modal is open
// router.beforeEach(trackRecentForms);
// router.beforeEach()

// error handler to not show "NavigationDuplicated" errors
const onError = (e) => {
    if (e.name !== 'NavigationDuplicated') throw e;
};

const _push = router.__proto__.push;

router.__proto__.push = function push(...args) {
    try {
        const op = _push.call(this, ...args);
        if (op instanceof Promise) op.catch(onError);
        return op;
    } catch (e) {
        onError(e);
    }
};

export default router;
