export const insuranceTypeOptions = ['Primary', 'Secondary', 'Teritiary'];
export const relationToInsuredOptions = ['Self', 'Spouse', 'Mother', 'Father', 'Child', 'Employee', 'Other Relationship', 'Unknown'];
export const planTypeOptions = ['HDHP', 'PPO', 'HMP', 'EPO', 'POS', 'Catastrophic Plan'];
export const deductibleMetOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const hasDeductibleBeenMetOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const isPolicyCurrentlyActiveOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const isProvideInNetworkOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const isThereDeductibleOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const limitExcludeDocumentRequirementOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const outOfNetworkBenefitsOptions = ['Yes', 'No'];
export const isAuthRequiredOptions = [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 0 }
];
export const isReferralRequiredOptions = ['Yes', 'No'];
export const timelineActivity = [
    { text: 'All Activity', value: 'all' },
    //TODO - logroll this.
    { text: 'Appointments', value: 'appointment' },
    { text: 'Chart Notes', value: 'chart' },
    { text: 'Emails', value: 'email' },
    { text: 'Forms', value: 'forms' },
    { text: 'Payments', value: 'payment' },
    { text: 'Toxicology', value: 'toxicology' },
    { text: 'Progress Notes', value: 'progress' },
    { text: 'Activity Reminders', value: 'activity_reminders' }
];
export const timelineOrder = [
    { text: 'Newest to Oldest', value: 'Descending' },
    {
        text: 'Oldest to Newest',
        value: 'Ascending'
    },
    { text: 'Custom', value: 'custom' }
];
//export const statusOptions = [{text:"Active", value: "active"}, {text: 'Inactive', value:"inactive"}, {text: "Intake", value: "intake"}, {text:"Lead", value:"lead"}];
export const statusOptions = ['Active', 'Inactive', 'Intake', 'Lead'];

/**
 * Form
 */
// export const forms = [
//     {category: 'General', availableForms: [{}]},
//     {category: 'Clinical Assessments', availableForms: [{}]},
//     {category: 'Standardized Screening Tools', availableForms: [{}]},
//     {category: 'Addiction Treatment', availableForms: [{}]},
// ]
// {title: "Certificate of Completion", desc: "Certificate for a client's successful completion of their treatment program.", to: ""}
// {title: "Consent for Release of Information", desc: "Permission to share a client's treatment information and records to other parties.", to: ""},
// {title: "Initial Service agreement", desc: "Agreement to services, fees, terms, and expectations during treatment.", to: ""},
// {title: "PCP Communication Form", desc: "Consent to exchange medical informaiton with PCP to facilitate coordination of care.", to: ""},

// {title: "Individual Treatment Plan", desc: "Assessment and plan of care agreed upon by clinician and client.", to: ""},
// {title: "Mental Health Biopsychosocial", desc: "Condensed, less invasive version of the Subtanance Abuse Biopsychosocial.", to: ""},
// {title: "Substance Abuse Biopsychosocial", desc: "Used to collect a full history on the client's background.", to: ""},
// {title: "Treatment Review", desc: "Assessment of a client's progress and response to treatment over a period of time", to: ""},
// {title: "Discharge Report", desc: "Client had completed treatment or needs to be discharged from care", to: ""}

// {title: }

export const stateInitials = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
];
