<!-- eslint-disable vue/valid-v-if -->
<template>
    <div class="header do-not-print-me">
        <div class="d-flex align-items-center">
            <div  class="logo">
                <img class="logo" src="@/assets/px/logo.png" alt="MobexHealthHub" title="MobexHealthHub" />
            </div>
       
            <label for="sidebar-expand" class="sidebar-expand sidebar-expand ms-4 me-3" role="button">
                <div class="menu-open-outer-box">
                    <span class="menu-open"><img :class="isSidebarExpanded ? 'checked-menu-open' : 'unchecked-menu-open'" src="@/assets/px/header-icons-images/toggle.png" style="width: 22px;"/></span>
                    <!-- <span class="material-icons-outlined menu-open" :class="isSidebarExpanded ? 'checked-menu-open' : 'unchecked-menu-open'">menu_open</span> -->
                </div>
            </label>
       
            <div v-if="showMenuTitle" class="d-flex flex-column">
                <span class="fs-5 fw-bold header-welcome-text">Welcome {{ usersFirstName }}!</span>
                <small class="header-welcome-summary-text">Here's your summary for {{ today }}</small>
            </div>
            <div v-else>
                <span class="fs-4 fw-bold" v-if="(pageMeta.title).toString().includes('Client')">{{pageMeta.title.replace("Client", "Member") }}</span>
                <span class="fs-4 fw-bold" v-else>{{ pageMeta.title}}</span>
            </div>
        </div>    
        <audio id='meetingAudioElement'></audio>
        <router-link v-if="this.$store.state.chime_meeting_id" :to="{ name: 'VideoConferences' }" class=" flex left">
            <span class="icon-wrapper"><b class="material-icons icon">videocam</b></span>
            <span class="text">&nbsp; Meeting Active</span>
        </router-link>
        <div class="header-right">
            <!-- <template v-if="aws_env">
                <div v-html="linx" class="jira-linx"></div>
                {{ this.$store.state.current_client_id }}
            </template> -->
            <CreateResourceDropdown />
            <MenuDropdown :userId="userId" :userName="userName" :userRoleId="userRoleId" :userProfileImg="userProfileImage"/>

            <!-- Currently made hidden since its not functional -->
                <!-- <span class="header-nav-right-content mx-2" role="button">
                    <ejs-tooltip
                        class="tooltipcontainer"
                        content="Personalize Settings"
                        target="#target"
                        cssClass="customtooltip"
                    >
                        <img class="img-equalizer" id="target" src="@/assets/px/header-icons-images/equalizer.png"/>
                    </ejs-tooltip>
                </span> -->
            <!-- Currently made hidden since its not functional -->

            <!-- <span class="material-icons mx-2" role="button">tune</span> -->
        </div>
    </div>
</template>

<script>
    import api from '@/util/api';
    import { users, file } from '@/util/apiRequests'
    import CreateResourceDropdown from '@/components/layout/CreateResourceDropdown';
    import { mapGetters, mapState } from 'vuex';
    import MenuDropdown from '@/components/layout/MenuDropdown';
    import dayjs from '@/util/dayjs';
    import advancedFormat from 'dayjs/plugin/advancedFormat';
    dayjs.extend(advancedFormat);

    export default {
        name: 'Header',
        components: { MenuDropdown, CreateResourceDropdown },
        props: {
            isSidebarExpanded: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                linx: '',
                render_header_again: true,
                status_color: 'light-grey',
                aws_env: 0,
                today: '',
                userProfileImage: null
            };
        },
        computed: {
            ...mapState({ user: 'user', subMenuName: 'subMenuName' }),
            ...mapGetters(['menuPages']),
            userId() {
                return this.user.id;
            },
            userName() {
                return this.user.name;
            },
            userRoleId() {
                return this.user.role_id;
            },
            usersFirstName() {
                return this.user.user_detail.firstName;
            },
            showMenuTitle() {
                return ['Dashboard'].includes(this.$route.name);
            }
        },
        methods: {
            async signOut() {
                try {
                    api.post('/auth/sign-out');
                } catch (err) {
                    console.error(err);
                } finally {
                    document.cookie = 'chirp_notes_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    this.$store.commit('clearUser');
                    this.$store.commit('clearGoogleTokens');
                    await this.$store.dispatch('recent/clearStore');
                    // await this.$router.go()
                    await this.$router.push({ name: 'SignIn' });
                }
            },
            async getUserProfileImage() {   
                try {
                    let profilePhotoUrl = await this.getProfilePhotoDownloadUrl();
                    if (!profilePhotoUrl) {
                        return null;
                    }
                    
                    let response = await this.$api.get(profilePhotoUrl);
                    this.userProfileImage = response?.data?.file?.Body ? `data:image;base64, ${response?.data?.file.Body}` : null;
                } catch (err) {
                    console.error(err);
                }            
            },
            async getProfilePhotoDownloadUrl() {
                try {
                    const response = await this.$api.get(users.getHeaderDetails(this.userId));
                    if (!response) {
                        throw 'err';
                    }

                    let { profilePhotoId } = response.data;
                    return file.getProfilePhoto(profilePhotoId);
                } catch (err) {
                    if (err) {
                        return '';                    
                    }
                }
            },
            showmejira() {
                if (this.pageMeta.page_status) {
                    this.status_color = this.pageMeta.page_status;
                }
                if (this.pageMeta.jira_tickets) {
                    let jira_url = 'https://chirpnotes.atlassian.net/browse/';
                    let proj_prefix = 'BM-';
                    let jira = this.pageMeta.jira_tickets.split(',');
                    jira.forEach((v) => {
                        // (v, k) => {
                        this.linx +=
                            ' &nbsp; <a style="background-color:' +
                            this.status_color +
                            ';" href="' +
                            jira_url +
                            proj_prefix +
                            v +
                            '" target="_jira"> | ' +
                            proj_prefix +
                            v +
                            ' | </a> &nbsp; ';
                    });
                }
            },
            getCurrentFormattedDate() {
                let currDate = new Date();
                return dayjs(currDate).format('MMMM Do, YYYY') ;
            },
        },
        created() {
            this.today = this.getCurrentFormattedDate();
            this.aws_env = process.env.VUE_APP_AWS_ENV == 'dev';
            this.showmejira();
            this.getUserProfileImage();
        },
        watch: {
            pageMeta: function() {
                this.linx = '';
                this.showmejira();
            }
        }
    };
</script>

<style scoped>
    .menu-open-outer-box {
        border-radius: 50%;
        background: #F2F3F8;
        padding: 0.1rem;
        color: rgba(81, 85, 195, 0.95);
        width: 2.3rem;
        height: 2.3rem;
    }
    .menu-open {
        position: relative;
        top: 3px;
        left: 4px;
    }

    .img-equalizer {
        width: 23px;
        margin: 0.2em;
    }
</style>
